@import './../../../styles/global.variables';

#AgentContractsTableComponent {
  box-shadow: none;

  .card-header {
    background-color: $color--usabg--blue;
    text-align: left;
    color: #fff;
    padding: 10px 5px;

    & > .row {
      & > div {
        .btn.contracts-store-btn {
          font-family: 'Lato';
          font-weight: 600;
          font-size: 1rem;
          height: calc(100% - 15px);
        }

        & > div {
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 1.25rem;
        }

        & > small {
          text-transform: none;
          letter-spacing: 0;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    overflow: hidden;
    border: 1px solid rgb(180, 180, 180);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);

    #AgentContractTableSelector {
      background-color: rgb(220, 220, 220);
      display: flex;
      padding: 8px 5px;
      margin: 0;

      & > .view-state-label {
        color: rgb(120, 120, 120);
        padding: 5px 15px 5px 5px;
        display: flex;
        align-self: end;
        margin: 0;
        font-weight: 500;
        flex-wrap: wrap;

        label {
          width: 100%;
          margin: 0;
        }
      }

      & > .view-state-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        margin-left: auto;

        & > li {
          text-align: center;
          padding: 5px 15px;
          display: flex;
          align-self: center;
          align-items: center;
          background-color: rgb(240, 240, 240);
          border: thin solid rgb(180, 180, 180);
          cursor: pointer;
          font-weight: 500;
          font-size: 0.9rem;
          transition-property: background-color, color;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;

          & + li {
            margin-left: 0;
          }

          &:first-of-type {
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
          }

          &:last-of-type {
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
          }

          .view-state-pill {
            display: inline-block;
            height: 1.2rem;
            font-size: 0.8rem;
            font-weight: bold;
            background-color: rgb(180, 180, 180);
            line-height: 1.1rem;
            margin: 0;
            margin-right: 2px;
            padding: 0;
            border: thin solid transparent;
            border-radius: 0.6rem;
            padding: 0 6px;
            color: rgb(40, 40, 40);
          }

          &:hover {
            background-color: rgb(230, 230, 230);
          }
        }

        & > li.view-state-current {
          &.invalid-state {
            color: $color--invalid;

            .view-state-pill {
              background-color: $color--invalid;
              color: #fff;
            }
          }
        }

        &.view-active-current {
          & > li.view-state-current {
            background-color: purple;
            color: rgb(240, 240, 240);

            .view-state-pill {
              background-color: #fff;
            }

            &.invalid-state {
              .view-state-pill {
                color: $color--invalid;
              }
            }
          }
        }

        &.view-active-expired {
          & > li.view-state-expired {
            background-color: purple;
            color: rgb(240, 240, 240);

            .view-state-pill {
              background-color: #fff;
            }
          }
        }
      }
    }

    div.agent-carriers-table {
      width: 100%;

      & > .agent-carrier-table-row:not(.alert-row) {
        display: flex;

        .is-priority.is-core,
        .is-priority.is-preferred,
        .is-priority.is-additional {
          display: inline;
        }

        &.carrier-is-core {
          .is-priority.is-preferred,
          .is-priority.is-additional {
            display: none;
          }
        }

        &.carrier-is-preferred {
          .is-priority.is-core,
          .is-priority.is-additional {
            display: none;
          }
        }

        &.carrier-is-additional {
          .is-priority.is-preferred,
          .is-priority.is-core {
            display: none;
          }
        }

        .index {
          border-left: thin solid rgb(140, 140, 140);
          border-right: thin solid rgb(140, 140, 140);
          background-color: rgb(160, 160, 160);
          font-weight: bold;
          text-align: center;
          color: rgb(140, 140, 140);
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          & > div {
            border-radius: 50%;
            border: 2px solid rgb(140, 140, 140);
            background-color: white;
            height: 30px;
            width: 30px;
            line-height: 26px;
            margin: auto;
            display: flex;
            justify-content: center;
          }

          .is-priority {
            margin: 0;
            color: rgb(220, 220, 220);
            text-align: center;
            display: block;
            width: 100%;
          }
        }

        & > .identity {
          padding: 5px;

          h5,
          h6 {
            font-weight: 600;
            font-family: 'Lato';
          }

          .contract-descriptor {
            width: calc(100% - 1rem);
            margin-left: 1rem;
          }

          .alert.carrier-priority-pill {
            font-weight: bold;
            font-size: 0.75rem;
            padding: 2px;
            margin: 2px;
            opacity: 0.8;
          }
        }

        .dispo-dates-splitter > .disposition,
        .disposition {
          font-weight: bold;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
          text-align: center;
          padding: 5px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;

          .alert.requires-attention {
            width: 100%;
            padding: 2px;
            font-size: 0.8rem;
            margin: 0;
          }

          .agent-writing-code {
            color: rgb(60, 60, 60);
            font-weight: 500;
            font-size: 0.85rem;
            width: 100%;
          }
        }

        .dispo-dates-splitter > .disposition,
        .disposition {
          & > i.fa {
            display: none;
          }

          &.pending {
            color: #856404;
            background-color: #fff3cd;
            border-color: #ffeeba;

            & > i.fa.fa-clock {
              display: inline;
            }
          }

          &.awaiting {
            background-color: #fff3cd;
            border-color: #ffeeba;
          }

          &.completed {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;

            & > i.fa.fa-flag-checkered {
              display: inline;
            }
          }
        }

        .contract-dates {
          padding: 0 5px;
          border-left: 1px dashed rgb(180, 180, 180);
          border-right: 1px dashed rgb(180, 180, 180);
        }

        & > .contract-action-btns {
          display: flex;
          flex-wrap: wrap;
          padding: 5px;

          & > div {
            flex: 0 0 calc(100% - 2px);
            max-width: calc(100% - 2px);
            margin: 1px 1px;
          }
        }

        & + .agent-carrier-table-row {
          &.with-siblings {
            border-top: none;

            & > div:first-of-type {
              border-top: none;
            }

            &:not(:last-of-type) {
              & > .disposition,
              & > .contract-dates,
              & > div:last-of-type {
                border-bottom: 1px dotted rgb(150, 150, 150);
              }
            }
          }

          &:not(.with-siblings) {
            border-top: 1px solid rgb(150, 150, 150);

            & > div:first-of-type {
              border-top: 1px solid rgb(140, 140, 140);
            }

            & + .with-siblings {
              border-top: 1px solid rgb(150, 150, 150);
            }
          }
        }

        &.carrier-is-core {
          & > .index {
            // color: #155724;
            // background-color: #d4edda;
            // border-color: #c3e6cb;
            // color: #155724;
            background-color: #29733a; //#155724;
            border-color: #29733a; //#155724;
            color: #155724;

            .is-priority {
              color: #fff;
            }
          }
        }

        &.carrier-is-preferred {
          & > .index {
            // color: #0c5460;
            // background-color: #d1ecf1;
            // border-color: #bee5eb;
            color: #0c5460;
            background-color: #2b7f8d; //#0c5460;
            border-color: #2b7f8d; //#0c5460;

            .is-priority {
              color: #fff;
            }
          }
        }

        &.carrier-is-additional {
        }
      }

      .expire-btn,
      .unexpire-btn,
      .writing-num-btn,
      .guide-btn,
      .download-btn,
      .addtl-docs-btn {
        margin: 0;
        padding: 2px 5px;
        background-color: rgb(240, 240, 240) !important;
        box-shadow: none;
        text-align: center;
        transition-property: background-color, color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        margin-bottom: 3px;
        opacity: 0.6;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        &:hover {
          opacity: 1;
        }

        & > i.fa,
        i.fas {
          display: inline-block;
          height: 25px;
          line-height: 25px;
          width: 20px;
        }

        & > span {
          display: inline-block;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 15px;
          font-family: 'Roboto', sans-serif;
          width: calc(100% - 22px);
        }
      }

      .expire-btn {
        color: $color--invalid;
        &:hover {
          background-color: $color--invalid !important;
          color: #fff;
        }
      }

      .unexpire-btn {
        color: $color--success;
        &:hover {
          background-color: $color--success !important;
          color: #fff;
        }
      }

      .writing-num-btn {
        color: blue;
        &:hover {
          background-color: blue !important;
          color: #fff;
        }
      }

      .download-btn {
        color: $color--success;
        &:hover {
          background-color: $color--success !important;
          color: #fff;
        }
      }

      .addtl-docs-btn {
        color: blue;
        &:hover {
          background-color: blue !important;
          color: #fff;
        }
      }

      .invalid-action {
        font-size: 0.7rem;
        font-weight: 500;
        text-align: center;
      }

      .guide-btn-icon {
        padding-left: 3px;
      }
    }
  }

  // xs
  @media screen and (max-width: 767px) {
    #AgentContractTableSelector {
      flex-wrap: wrap;

      & > .view-state-label label {
        margin-bottom: 10px;
      }
    }

    .card-body {
      div.agent-carriers-table {
        width: 100%;

        & > .agent-carrier-table-row:not(.alert-row) {
          flex-wrap: wrap;

          & > .index {
            flex: 0 0 40px;
            max-width: 40px;
          }

          & > .identity {
            flex: 0 0 calc(100% - 40px);
            max-width: calc(100% - 40px);
          }

          & > .dispo-dates-splitter {
            display: none;
          }

          & > .disposition {
            flex: 0 0 100%;
            max-width: 100%;
          }

          & > .contract-dates {
            flex: 0 0 100%;
            max-width: 100%;
            border-color: transparent !important;
          }

          & > .contract-action-btns {
            display: flex;
            flex-wrap: wrap;
            padding: 5px;

            border: thin solid blue;

            & > div {
              flex: 0 0 calc(50% - 2px);
              max-width: calc(50% - 2px);
              border: thin solid green;
              margin: 1px 1px;
            }
          }

          // & > .contract-action-btns {
          //   .expire-btn,
          //   .unexpire-btn,
          //   .writing-num-btn,
          //   .download-btn {
          //     justify-content: center;

          //     &:nth-of-type(even) {
          //       margin-left: 2px;
          //     }
          //   }
          // }
        }
      }
    }
  }

  // sm
  @media screen and (min-width: 576px) {
    div.agent-carriers-table {
      & > .agent-carrier-table-row:not(.with-siblings):not(.alert-row) {
        & > .identity {
          border-bottom: 1px solid rgb(220, 220, 220);
        }

        & > .disposition {
          flex: 0 0 45%;
          max-width: 45%;
        }

        & > .contract-dates {
          flex: 0 0 55%;
          max-width: 55%;
        }
      }
      & > .agent-carrier-table-row.with-siblings:not(.alert-row) {
        & > .identity {
          border-bottom: none;
        }
      }
    }
  }

  // md
  @media screen and (min-width: 768px) and (max-width: 991px) {
    div.agent-carriers-table {
      width: 100%;

      & > .agent-carrier-table-row:not(.alert-row) {
        & > .index {
          flex: 0 0 40px;
          max-width: 40px;
        }

        & > .identity {
          flex-grow: 1;
        }

        & > .disposition,
        & > .contract-dates {
          display: none !important;
        }

        & > .dispo-dates-splitter {
          flex: 0 0 18rem;
          max-width: 18rem;
          display: flex;
          flex-wrap: wrap;

          & > .disposition {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;

            .alert.requires-attention {
              margin: 0;
              padding: 2px;
            }
          }

          & > .contract-dates {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        & > div:last-of-type {
          flex: 0 0 9.5rem;
          max-width: 9.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 850px) {
    div.agent-carriers-table {
      & > .agent-carrier-table-row:not(.alert-row) {
        & > .dispo-dates-splitter {
          flex: 0 0 20rem;
          max-width: 20rem;
        }
      }
    }
  }

  // lg
  @media screen and (min-width: 992px) {
    .card-body {
      div.agent-carriers-table {
        width: 100%;

        & > .agent-carrier-table-row:not(.alert-row) {
          & > .index {
            flex: 0 0 40px;
            max-width: 40px;
          }

          & > .identity {
            flex-grow: 1;
          }

          & > .dispo-dates-splitter {
            display: none;
          }

          & > .disposition {
            flex: 0 0 25%;
            max-width: 25%;
            display: flex;
          }

          & > .contract-dates {
            flex: 0 0 30%;
            max-width: 30%;
            display: flex;
          }

          & > div:last-of-type {
            flex: 0 0 9rem;
            max-width: 9rem;
          }
        }
      }
    }
  }

  // xl
  @media screen and (min-width: 1200px) {
  }
}

table#contract-delivery-table {
  thead {
    tr {
      background-color: rgba(120, 120, 120, 0.4);

      th {
        text-align: center;
        padding: 4px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(20, 20, 20, 0.9);
        &:nth-of-type(4) {
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-of-type(even) {
        background-color: rgba(220, 220, 220, 0.25);
      }
      td {
        text-align: center;
        padding: 4px;
        font-size: 14px;
        font-weight: 500;
        &:nth-of-type(4) {
          text-align: left;
        }
      }

      &.attempt-success {
        td:nth-of-type(1),
        td:nth-of-type(3) {
          background-color: $color--success;
          color: #fff;
        }
      }

      &.attempt-danger {
        td:nth-of-type(1),
        td:nth-of-type(3) {
          background-color: $color--invalid;
          color: #fff;
        }
      }
    }
  }
}
