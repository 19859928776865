#PerformanceForm {
  .metric-intake {
    font-weight: 500;
    padding: 0.25rem 0;

    label {
      margin: 3px 0 0 0;
    }

    span {
      font-size: 0.8rem;
    }

    .md-form {
      margin: 0;
    }

    & + .metric-intake {
      border-top: 1px solid rgb(240, 240, 240);
    }
  }
}

.vertical-label-container {
  display: flex;
  align-items: center; /* Vertically center the label */
  justify-content: center; /* Horizontally center the label */
  // height: 100%; /* Ensure the container spans the full height */
  background-color: #f0f0f0; /* Add a light gray background color */
}

.vertical-label-container.col-1 {
  flex: 0 0 3.333333%;
  max-width: 8.333333%;
  padding: 0.5rem 0;
}

.vertical-cyan {
  background-color: #00ffff; /* Add a light gray background color */
}

.vertical-blue {
  background-color: #3f51b5; /* Add a light gray background color */
}

.vertical-label-white {
  color: white !important;
}

.vertical-label {
  writing-mode: sideways-lr;
  text-orientation: upright; /* Ensures letters are upright and stacked */
  white-space: nowrap; /* Prevents the text from wrapping */
  margin: 0; /* Remove default margin */
  letter-spacing: 0.3em; /* Adds spacing between letters for better readability */
  font-size: 0.8em; /* Smaller font size */
  font-weight: bold; /* Optional: Make the text bold */
  color: #333; /* Darker text color for better contrast */
}
