@import 'src/styles/global.variables';

#ReportFilters {
  margin-bottom: 1rem;

  .btn.btn-blue {
    border-color: #2979ff;
    background-color: #2979ff !important;
  }

  .btn.btn-red {
    border-color: #ff1744;
    background-color: #ff1744 !important;
  }

  .btn.btn-green {
    border-color: #28b84f;
    background-color: #28b84f !important;
  }

  .btn.btn-orange {
    border-color: #eb7d34;
    background-color: #eb7d34 !important;
  }

  .btn.btn-purple {
    border-color: $color--purple;
    background-color: $color--purple !important;
  }

  .btn.btn-caramel {
    border-color: $color--caramel;
    background-color: $color--caramel !important;
  }

  .btn {
    margin-bottom: 10px;
    border: thick solid black;
    font-weight: 500;
    white-space: nowrap;
    padding: 10px 3px;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .fa {
    visibility: visible;
    width: 1rem;
    margin-right: 5px;
  }

  .btn:not(.btn-active) {
    .fa {
      visibility: hidden;
      width: 0;
      margin-right: 0;
    }
  }

  .remove-aca-av-toggle {
    width: 100%;

    & > .container {
      padding-left: 0;
      margin-left: 0;

      & > .row {
        padding-left: 0;
        margin-left: 0;

        & > .col {
          padding-left: 0;
          margin-left: 0;
          background-color: rgba(210, 210, 210, 0.8);
          border: thin solid rgb(210, 210, 210);
          border-radius: 0.2rem;

          .form-check {
            padding-left: 5px;
            font-weight: bold;
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }
}
