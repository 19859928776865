@import 'src/styles/global.variables';

#ContactManagerComponent {
  .row.auth-location {
    .login-crm-btn {
      background: $color--usabg--blue !important;
      color: #fff;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      border: thin solid transparent;
      border-radius: 0.3rem;
      margin-bottom: 0.5rem;
    }
  }

  .row.start-contact {
    & > div:last-of-type {
      margin: auto;

      & > button {
        display: block;
        margin: auto;
        width: 100%;
      }
    }
  }

  .row.selected-contact {
    .alert {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      & > span {
        display: flex;
        flex-grow: 1;
        font-weight: 500;
        font-size: 14px;

        & > span {
          color: #666;
          font-size: 13px;
        }
      }

      & > button.btn {
        display: flex;
        flex-shrink: 1;
        padding: 4px 8px;
        align-items: center;
        justify-content: center;
        font-size: 13px !important;
      }
    }
  }
}
