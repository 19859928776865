@import 'src/styles/global.variables';

#GrowthWidgetAgentSelectorComponent,
#GrowthWidgetCarrierSelectorComponent {
  .dropdown-wrapper {
    position: relative;
    // border: thick solid orange;
    min-width: 20rem;
    .dropdown-inner {
      position: absolute;
      left: 1rem;
      top: -1rem;
      // border: thick solid purple;
      z-index: 500;
      width: 100%;
      max-height: 50vh;
      overflow-y: auto;
      -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);

      ul {
        background-color: #fff;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          padding: 2px 6px;
          min-height: 52px;
          cursor: pointer;

          &:nth-of-type(even):not(:hover) {
            background-color: rgba(200, 200, 230, 0.2);
          }

          span {
            font-weight: 500;
          }

          span {
            display: block;
          }

          small {
            font-size: 12px;
            line-height: 14px;
            color: rgb(90, 90, 90);
          }

          &:hover {
            span {
              color: $color--usabg--blue;
            }
            background-color: rgba(200, 200, 230, 0.35);
          }
        }
      }
    }
  }
}

#AgentWidgetComponent,
#CarrierWidgetComponent {
  ul.source-buttons {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    & > li {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        flex: 0 0 33.3%;
        max-width: 33.3%;
        flex-grow: 1;
      }

      padding: 0;
      margin: 0;

      button.btn {
        font-weight: 600 !important;
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        padding: 5px 7px;
        margin: 0;
        box-shadow: none;
        font-family: Roboto;
        height: 100%;
        border-radius: 0.2rem;

        &:not(.btn-blue-grey) {
          i.fa {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    ul.source-buttons {
      & > li {
        &:nth-of-type(1) {
          button.btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(2) {
          button.btn {
            border-radius: 0;
          }
        }

        &:nth-of-type(3) {
          button.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:nth-of-type(4) {
          button.btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:nth-of-type(5) {
          button.btn {
            border-radius: 0;
          }
        }

        &:nth-of-type(6) {
          button.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    ul.source-buttons {
      & > li {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          flex: 0 0 50%;
          max-width: 50%;
          flex-grow: 1;
        }

        &:nth-of-type(1) {
          button.btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(2) {
          button.btn {
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &:nth-of-type(3) {
          button.btn {
            border-radius: 0;
          }
        }

        &:nth-of-type(4) {
          button.btn {
            border-radius: 0;
          }
        }

        &:nth-of-type(5) {
          button.btn {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:nth-of-type(6) {
          button.btn {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  // @media screen and (min-width: 576px) and (max-width: 767px) {
  // }
  // @media screen and (min-width: 768px) {
  // }
  // @media screen and (min-width: 992px) {
  // }
  // @media screen and (min-width: 1200px) {
  // }
}
