#SalesTrackerPage {
  .skip-crm-btn {
    margin-left: auto;
    float: right;
    background: transparent !important;
    font-size: 12px;
    font-weight: 500;
    color: #999;
    border: thin solid transparent;
    border-radius: 0.3rem;
  }

  ul.policy-errors-list {
    padding: 0 0 0 10px;
    margin: 0 10px;

    & > li {
      font-size: 14px;
      font-weight: 500;

      & + li {
        margin-top: 1rem;
      }
    }
  }
}
