@import './../../../styles/global.variables';

.ContractingPacketComponent {
  margin-bottom: 15px;
  border: 1px solid rgba(190, 190, 190, 1);
  border-radius: 0.4rem;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  & > .cart-group-wrapper {
    overflow: hidden;
    flex: 0 0 calc(100% - 10rem);
    max-width: calc(100% - 10rem);
    transition-property: flex, width, max-width;
    transition-duration: 1s;
    timing-function: ease-in-out;

    .group-indicator {
      background-color: $color--usabg--blue;
      color: #fff;
      padding: 3px 0;
      transition-property: font-size;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      display: flex;

      .ind-bg {
        margin: 0;
        padding: 4px 6px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: Roboto;
        flex: 0 0 100%;
        max-width: 100%;
        transition-property: flex, max-width;
        transition-duration: 0.25s;
      }

      .btn {
        font-size: 1rem;
        flex: 0 0 0;
        max-width: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: transparent !important;
        opacity: 0;
        transition-property: flex, max-width, opacity, color;
        transition-duration: 0.25s;
        border: none;
        box-shadow: none;

        &:active,
        &:focus-visible {
          background-color: transparent !important;
          box-shadow: none !important;
        }
      }
    }

    .group-contracts-heading {
      display: flex;
      width: 100%;

      & > div {
        background-color: #c2c2c2;
        color: #666;
        font-weight: 500;
        font-size: 0.9rem;
        padding: 3px 0 3px 5px;
      }

      & > .carrier-descrip {
        flex: 0 0 40%;
        max-width: 40%;
      }
      & > .carrier-comm-level {
        flex: 0 0 15%;
        max-width: 15%;
      }
      & > .carrier-instruct {
        flex: 0 0 45%;
        max-width: 45%;
      }
    }

    .group-ctype-instruct {
      background-color: #d3d3d3;
      padding: 1rem 0.75rem 1.5rem 0.75rem;
      border-bottom: 1px solid #999;
    }
  }

  & > .cart-group-button {
    flex: 0 0 10rem;
    max-width: 10rem;
    transition-property: flex, width, max-width, visibility;
    transition-duration: 1s;
    timing-function: ease-in-out;
    overflow: hidden;

    .cancel-button {
      padding: 0;
      text-align: center;
      height: 40px !important;
      background-color: $color--invalid !important;
      border-bottom-left-radius: 0;
    }

    .view-button {
      height: calc(100% - 40px);
      border-top-left-radius: 0;
      background-color: $color--success !important;
    }

    .view-button.view-button-only {
      height: 100% !important;
      border-bottom-left-radius: 0;
    }

    button.btn {
      margin: 0;
      width: 100%;
      min-width: 10rem;
      font-family: 'Roboto';
      font-size: 1rem;
      transition-property: font-size;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }

    div.prep-time {
      width: 100%;

      small {
        line-height: 0.9rem;
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
        font-weight: 500;
      }
    }
  }

  ul.group-contracts {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 0;
      position: relative;
      margin-bottom: 20px;
      display: flex;
      transition-property: margin;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &:not(:last-of-type):after {
        position: absolute;
        height: 1px;
        bottom: -10px;
        background-image: linear-gradient(
            to right,
            rgba(190, 190, 190, 0.75) 8px,
            transparent 1px
          ),
          linear-gradient(rgba(190, 190, 190, 0) 1px, transparent 1px);
        background-size: 20px;
        content: ' ';
        width: 80%;
        margin-left: 10%;
      }

      &:first-of-type {
        margin-top: 10px;
      }

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }

  div.group-footer {
    display: flex;
    flex-wrap: wrap;
    background-color: #d3d3d3;
    border-top: 1px solid #999;

    div.contract-actions {
      flex: 0 0 100%;
      max-width: 100%;
      overflow: hidden;

      .row.btn-row {
        margin: 0;

        .btn {
          margin: 0;
          width: 100%;
          border-radius: 0;
          box-shadow: none;
        }

        & > div:first-of-type {
          flex: 0 0 100%;
          max-width: 100%;

          .btn {
            background-color: $color--success !important;
            font-size: 1.25rem;
          }
        }

        & > div:last-of-type {
          flex: 0 0 0%;
          max-width: 0%;
          overflow: hidden;
          height: 0;

          .btn {
            font-size: 0.8rem;
            font-weight: 600;
            font-family: 'Roboto';
          }
        }

        &:not(.can-mark-completed) {
          & > div:first-of-type {
          }
        }

        &.can-mark-completed {
          & > div:first-of-type {
            flex: 0 0 80%;
            max-width: 80%;
          }
          & > div:last-of-type {
            flex: 0 0 20%;
            max-width: 20%;
            height: auto;
          }
          .btn {
            height: 100%;
          }
        }
      }

      .btn-row.multiple {
        display: flex;
        width: 100%;
        gap: 0; // Remove gap to use borders instead

        // Base button styles
        .btn {
          flex: 1;
          min-width: 0;
          padding: 10px 8px; // Slightly reduced padding
          font-weight: 600;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;

          // Add right border to all except last button
          &:not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.4); // Light border
          }

          // Start buttons (all except last)
          &:not(:last-child) {
            background-color: $color--success !important;
            font-size: 0.95rem; // Reduced from 1.25rem
          }

          // Success button (last child)
          &:last-child {
            font-size: 0.85rem; // Reduced from 1rem
            font-weight: 700;
          }
        }

        // Mobile responsiveness
        @media (max-width: 768px) {
          flex-wrap: wrap;
          gap: 8px; // Small gap for mobile

          .btn {
            flex: 1 1 100px; // Smaller minimum width
            font-size: 0.8rem !important; // Even smaller on mobile

            // Remove side borders on mobile
            &:not(:last-child) {
              border-right: none;
            }

            // Add bottom border instead
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &.is-open {
    & > .cart-group-wrapper {
      flex: 0 0 100%;
      max-width: 100%;

      .group-indicator {
        .ind-bg {
          flex: 0 0 calc(100% - 3rem);
          max-width: calc(100% - 3rem);
        }

        .btn {
          flex: 0 0 3rem;
          max-width: 3rem;
          opacity: 1;
        }
      }
    }
    & > .cart-group-button {
      flex: 0 0 0;
      max-width: 0;
      visibility: hidden;
    }
  }

  &.is-disabled {
    opacity: 0.65;

    & > .cart-group-wrapper {
      flex: 0 0 100%;
      max-width: 100%;

      .group-indicator {
        // animate
        font-size: 0.8rem;
      }
    }
    & > .cart-group-button {
      flex: 0 0 0;
      max-width: 0;
      visibility: hidden;

      button.btn {
        font-size: 0;
      }
    }

    ul.group-contracts {
      li {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    div.group-footer {
      div.contract-actions {
        .row.btn-row {
          & > div:first-of-type {
            // flex: 0 0 80%;
            // max-width: 80%;
          }

          & > div:last-of-type {
            // flex: 0 0 20%;
            // max-width: 20%;
          }
        }
      }
    }
  }

  // @media screen and (min-width: 1200px) {
  //   max-width: 1140px;
  //   width: 1140px;
  // }

  // @media screen and (min-width: 1400px) {
  //   max-width: 1340px;
  //   width: 1340px;
  // }

  // @media (min-width: 768px) { max-width: 720px; }
  // @media (min-width: 576px) { max-width: 540px; }
  // @media (min-width: 1200px) { max-width: 1140px; }
  // @media (min-width: 992px) { max-width: 960px; }
  // @media (min-width: 768px) { max-width: 720px; }
  // @media (min-width: 576px) { max-width: 540px; }
}
