@import './../../../styles/global.variables';

#AgentContractGuideModalComponent {
  .modal-content > .modal-body {
    padding: 0;
    min-height: 300px;

    .row {
      margin: 0;
    }

    .container {
      padding-bottom: 16px;
    }

    .contacts-title,
    .contracting-instruction-title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      padding: 12px;
    }

    .contacts-cells,
    .contacts-not-found,
    .contracting-instruction {
      font-size: 0.9rem;
      font-weight: 400;
      padding: 8px;
    }

    .contacts-not-found {
      text-align: center;
    }

    .contacts-table-container {
      width: 100%;
      overflow-x: auto;

      table {
        th {
          font-weight: 500;
        }

        td {
          font-weight: 400;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .modal-content > .modal-body {
      max-height: 500px;
      overflow-y: auto;
    }
  }

  @media screen and (min-width: 501px) {
    .modal-content > .modal-body {
      & > table {
        margin: auto;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .modal-dialog.contract-guide-modal {
      max-width: 600px;
      width: 600px;
    }
  }

  @media screen and (min-width: 800px) {
    .modal-dialog.contract-guide-modal {
      max-width: 800px;
      width: 800px;
    }
  }

  @media screen and (min-width: 1000px) {
    .modal-dialog.contract-guide-modal {
      max-width: 1000px;
      width: 1000px;
    }
  }
}
