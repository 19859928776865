@import 'src/styles/global.variables';

.ContactsListComponent {
  width: 100%;

  .ui-input.md-form {
    margin-top: 0;
  }

  // & > ul {
  //   width: 100%;
  //   padding: 0;
  //   margin: 0;
  //   list-style-type: none;
  //   overflow: hidden;

  //   & > li {
  //     padding: 0 0 0 10px;
  //     margin: 0;
  //     height: auto;
  //     border-radius: 0.4rem;

  //     &:nth-of-type(odd) {
  //       background-color: rgba(0, 115, 255, 0.2);
  //     }

  //     & > span {
  //       width: calc(100% - 2.75rem);
  //     }

  //     button.btn {
  //       background-color: transparent !important;
  //       padding: 0;
  //       margin: 2px 3px 0 0;
  //       box-shadow: none;
  //       float: right;
  //       margin-left: auto;
  //       color: black;
  //       height: 1.25rem;
  //       width: 1.25rem;
  //       position: relative;
  //       border-color: transparent;

  //       i.fa,
  //       i.far,
  //       i.fas {
  //         position: absolute;
  //         opacity: 1;
  //         top: 2px;
  //         left: 3px;
  //         font-size: 14px;
  //         line-height: 1rem;
  //         margin: auto;
  //         transition-property: color, opacity;
  //         transition-duration: 0.2s;
  //         transition-timing-function: ease-in-out;

  //         &.on-hover {
  //           opacity: 0;
  //         }
  //       }

  //       &:active,
  //       &:focus {
  //         box-shadow: none;
  //       }
  //     }

  //     &:not([disabled]):hover {
  //       button.btn {
  //         &:hover {
  //           background-color: $color--usabg--blue !important;
  //         }

  //         i.fa,
  //         i.far,
  //         i.fas {
  //           opacity: 0;

  //           &.on-hover {
  //             opacity: 1;
  //           }
  //         }
  //       }
  //     }

  //     &.hide-contact {
  //       height: 0;
  //       opacity: 0;
  //       visibility: hidden;

  //       button.btn {
  //         display: none;
  //       }
  //     }

  //     &:not(.hide-contact) {
  //       & + li {
  //         border-top: 1px dotted rgba(0, 0, 0, 0.1);
  //       }
  //     }

  //     &.active-contact {
  //       margin-bottom: 1rem;
  //     }

  //     &.show-contact {
  //     }
  //   }
  // }
}
