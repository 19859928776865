@import './../../../styles/global.variables';

#LoginComponent {
  position: relative;

  & > .card-header {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  & > .card-body {
    padding: 2px;
  }

  form {
    div.accept-terms {
      padding: 10px 10px 0 10px;

      small {
        color: rgb(60, 60, 60);
        font-weight: 500;
      }
    }
  }

  div.modal {
    div.modal-content {
      .modal-header {
        background-color: $color--usabg--blue;

        h4 {
          text-align: center;
          font-weight: 500;
          padding-left: 60px;
        }
      }

      .modal-body {
        max-height: 80vh;
        overflow: auto;

        .loading-spinner {
          text-align: center;

          table {
            margin: auto;
            min-height: 20vh;

            td {
              color: rgb(100, 100, 100);
              padding: 1rem;
              font-size: 1.75rem;
              font-weight: 500;
            }
          }

          i.fa {
            font-size: 2.5rem;
          }
        }

        ul {
          li {
            color: black;
            a {
              color: blue;
            }

            text-align: left;
            font-weight: 500;

            & + li {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    div.modal {
      & > div.modal-dialog {
        max-width: calc(90vw - 5vw);
        margin: auto;
        margin-top: 7vh;
      }
    }
  }
}

#SignatureAgentTermsAcceptanceComponent {
  div.sig-term-accept-check {
    display: flex;
    padding-top: 1rem;

    & > .check-input {
      display: flex;
      .form-check {
        padding-left: 0;
      }
    }

    & > .check-label {
      display: flex;
      flex: 0 0 calc(100% - 3rem);
      max-width: calc(100% - 3rem);
      text-align: left;
      flex-direction: column;

      & > .clabel,
      & > .chandler {
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;
        max-width: 100%;
        white-space: break-spaces;
        text-align: left;
      }

      & > .clabel {
        color: rgb(60, 60, 60);
      }

      & > .chandler {
        color: rgb(100, 100, 255);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & > .modal > .modal-dialog {
    margin-left: 15px !important;
    margin-right: 15px !important;
    width: calc(100% - 30px) !important;
    max-width: calc(100% - 30px) !important;
    height: 86vh;

    & > .modal-content {
      height: 100%;

      & > .modal-body {
        padding: 3px;

        iframe {
          height: calc(86vh - 5rem) !important;
        }
      }
    }
  }
}
